// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-tsx": () => import("/opt/build/repo/src/templates/Project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-donate-tsx": () => import("/opt/build/repo/src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meet-the-team-components-member-member-tsx": () => import("/opt/build/repo/src/pages/meet-the-team/components/Member/Member.tsx" /* webpackChunkName: "component---src-pages-meet-the-team-components-member-member-tsx" */),
  "component---src-pages-meet-the-team-index-tsx": () => import("/opt/build/repo/src/pages/meet-the-team/index.tsx" /* webpackChunkName: "component---src-pages-meet-the-team-index-tsx" */),
  "component---src-pages-methodology-tsx": () => import("/opt/build/repo/src/pages/methodology.tsx" /* webpackChunkName: "component---src-pages-methodology-tsx" */),
  "component---src-pages-news-components-news-item-news-item-tsx": () => import("/opt/build/repo/src/pages/news/components/news-item/NewsItem.tsx" /* webpackChunkName: "component---src-pages-news-components-news-item-news-item-tsx" */),
  "component---src-pages-news-index-tsx": () => import("/opt/build/repo/src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-partner-tsx": () => import("/opt/build/repo/src/pages/partner.tsx" /* webpackChunkName: "component---src-pages-partner-tsx" */),
  "component---src-pages-projects-tsx": () => import("/opt/build/repo/src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-purpose-tsx": () => import("/opt/build/repo/src/pages/purpose.tsx" /* webpackChunkName: "component---src-pages-purpose-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("/opt/build/repo/src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

